/* src/index.css 또는 src/App.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

#root{
  width:100%;
  overflow:hidden;
  }

h2 {
  color: #007bff;
}

a {
  color: #007bff;
}

a:hover {
  text-decoration: underline;
}
